import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import moment from "moment";
import DfCoupon from "@/vue/domain/df-coupon";
import Firebase from "@/vue/infrastructure/utils/firebase";
import DfFirebaseTopicType from "@/vue/domain/firebase/df-firebase-topic-type";
import DfBrand from "@/vue/domain/brand/df-brand";

@Component({
  filters: {
    dateFormat(value: Date | string, locale = "it"): string {
      let result: string = null;
      if (value) {
        moment.updateLocale(locale, {
          sameDay: `[Today] ${Utils.DATE_STRING_HOURS_MINUTES}`,
          nextDay: `[Tomorrow]`,
          nextWeek: `dddd`,
          lastDay: `[Yesterday] ${Utils.DATE_STRING_HOURS_MINUTES}`,
          lastWeek: `[Last] ${Utils.DATE_STRING_DAY_WITH_HOURS}`,
          sameElse: ` ${Utils.DATE_STRING_PATTER_WITH_HOURS}`,
        });
        result = moment(value, Utils.ISO_8601_STRING_PATTERN).calendar();
      }
      return result;
    },
    percentual: (value: string) => {
      const data: string = new Intl.NumberFormat(navigator.language, {
        style: "decimal",
        minimumFractionDigits: 0,
      }).format(parseFloat((parseFloat(value) || 0).toFixed(0)));
      return `-${data}%`;
    },
    decimal: (value: string) => {
      return new Intl.NumberFormat(navigator.language, {
        style: "decimal",
        minimumFractionDigits: 2,
      }).format(parseFloat((parseFloat(value) || 0).toFixed(2)));
    },
    number: (value: number) => {
      return new Intl.NumberFormat(navigator.language).format(value);
    },
  },
})
export default class DfCardCouponComponent extends Vue {
  @Prop({ type: Object, default: null })
  coupon: DfCoupon;

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get dateAvailableTo(): string {
    return this.coupon?.availableTo ? moment(this.coupon.availableTo, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_PATTER) : "";
  }

  get daysToExpire(): number {
    let daysToExpire = 0;
    if (this.coupon?.availableTo) {
      daysToExpire = moment(this.coupon.availableTo, Utils.ISO_8601_STRING_PATTERN).diff(moment(), "days");
    }
    return daysToExpire;
  }

  get discount(): string {
    let discount: string = null;
    let data: string = null;
    if (this.coupon.discount) {
      switch (this.coupon.discountType) {
        case DfCoupon.DISCOUNT_PERCENT:
          data = new Intl.NumberFormat(navigator.language, {
            style: "decimal",
            minimumFractionDigits: 0,
          }).format(parseFloat((parseFloat(this.coupon.discount.toString()) || 0).toFixed(0)));
          discount = `-${data}%`;
          break;
        case DfCoupon.DISCOUNT_CASH:
          data = new Intl.NumberFormat(navigator.language,{
            style: "currency",
            currency: "EUR",
          }).format(parseFloat((parseFloat(this.coupon.discount.toString()) || 0).toFixed(2)));
          discount = `-${data}`;
      }
    }
    return discount;
  }

  get discountTrigger(): string {
    if (this.coupon.minMoney) {
      const minMoney = new Intl.NumberFormat(navigator.language,{
        style: "currency",
        currency: "EUR",
      }).format(parseFloat((parseFloat(this.coupon.minMoney.toString()) || 0).toFixed(2)));
      return this.$t("df-card-coupon.min-money", { 0: minMoney }).toString();
    } else if (this.coupon.maxMoney) {
      const maxMoney = new Intl.NumberFormat(navigator.language,{
        style: "currency",
        currency: "EUR",
      }).format(parseFloat((parseFloat(this.coupon.maxMoney.toString()) || 0).toFixed(2)));
      return this.$t("df-card-coupon.max-money", { 0: maxMoney }).toString();
    } else if (this.coupon.minUnits) {
      return this.$tc("df-card-coupon.min-units", this.coupon.minUnits, { 0: this.coupon.minUnits }).toString();
    } else if (this.coupon.maxUnits) {
      return this.$tc("df-card-coupon.max-units", this.coupon.minUnits, { 0: this.coupon.maxUnits }).toString();
    } else {
      return this.$tc("df-card-coupon.discount");
    }
  }

  get imageUrl(): string {
    return this.coupon.image || Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_COUPON_PREVIEW_IMAGE, null);
  }

  private checkChange() {
    this.$emit("check", this.coupon);
  }

  private toggleCoupon() {
    this.$emit("toggleCoupon", this.coupon);
  }
}
