import { render, staticRenderFns } from "./df-card-coupon.vue?vue&type=template&id=38781f56&scoped=true&"
import script from "./df-card-coupon.ts?vue&type=script&lang=js&"
export * from "./df-card-coupon.ts?vue&type=script&lang=js&"
import style0 from "./df-card-coupon.vue?vue&type=style&index=0&id=38781f56&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38781f56",
  null
  
)

export default component.exports